:root {
  --primary: #994B00;
}

.btn {
  margin-left: 8px;
  margin-right: 8px;
  padding: 8px 30px;
  border-radius: 60px;

  outline: white;
  border: 2px solid white;
  font-size: 1.3rem;
  color: #fff;
  background-color: transparent;
}

.btn:hover { 
  margin-left: 0px;
  margin-right: 0px;
  padding: 8px 38px;
  border-radius: 60px;
  outline: white;
  border: 2px solid white;
  font-size: 1.3rem;
  color: #fff;
  background-color: rgba(223, 158, 97, 0.648);
  transition: all 0.3s ease-out;
}
