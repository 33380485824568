body{font-family: Plus Jakarta Sans!important;color: #141414!important;}
* { margin: 0px; padding: 0px; box-sizing: border-box; }
a{text-decoration: none;}
h1, h2, h3, h4, h5, h6, p, ul{margin: 0px; padding: 0px;}
:focus-visible { outline: none;} 

.login_sect .w-full {
    display: flex;
    align-items: center;
}
.form_crd form {
    border: 0;
    background: #fff;
    padding: 0;
    margin: 0;
    width: 100%;
}

.login_frm_sect {background: #FFF;
    box-shadow: 10px 24px 54px 0px rgba(0, 0, 0, 0.06);
    margin-left: -10%;
    padding: 80px;
    width: 100%;
    max-width: 80%;
    z-index: 1;}
.frm_frog .otp_grp {display: flex;align-items: center;gap: 10px;}
.frm_frog .otp_grp .form-control {background: #F5EDE5;border: 1px solid #994B00; border-radius: 10px;width: 120px;height: 40px;display: flex;padding: 5px;align-items: center;text-align: center;justify-content: center;color: #171724;font-size: 16px;font-weight: 600;}
.frm_frog .pass_rest {color: #A3A3A3;font-size: 14px;font-weight: 400;}
.frm_frog .pass_rest a{color: #000;font-size: 14px;font-weight: 400; }
.frm_frog {display: flex;align-items: center;justify-content: space-between;margin-top: 45px;}
.form_crd h3 {color: #464646;font-size: 32px;font-weight: 600;margin-bottom: 20px;}
.form_crd .form-group {}
.form_crd .form-group .form-control {border-radius: 5px;border: 1px solid #EBEDF4;background: #FFF;height: 45px;width: 100%;padding: 8px 15px;color: #A3A3A3;font-size: 16px;font-weight: 400;line-height: 23px; } 
.form-group {}
.form_crd .form-group label {color: #464646;font-size: 14px;font-weight: 500;display: block;margin-bottom: 5px;}
.frm_frog .otp_grp .form-control:hover {background: #fff;}
.login_frm_sect .inner_bdy {border-radius: 5px;background: #FFF;box-shadow: 0px 1px 4px 0px #E5E9F2;padding: 30px; }
.login_frm_sect .inner_bdy .btn {display: block;text-align: center; border-radius: 77px;color: #FFF;font-size: 20px;font-weight: 500;text-transform: uppercase;background: #994B00;width: 100%;border: 0;padding: 8px 15px;margin-top: 55px;}
.heading_title {text-align: right;}
.heading_title a{color: #818181;text-align: right;font-size: 18px;font-weight: 600;letter-spacing: 0.09px;text-transform: capitalize;}
 .heading_title {position: absolute;top: 20px;right: 21px;}
.login_singup a {color: #FFF;font-size: 18px;font-weight: 500;display: flex;align-items: center;gap: 10px;}
.login_singup {padding: 15px  30px;margin-top: 70%;}
.login_sect .left_bx img {width: 100%;height: 100vh;
}
@media (min-width: 360px) and (max-width: 990px){
    .heading_title a{color: #fff;}
    .login_frm_sect{max-width: 100%; margin: 0 15px;}
    .login_sect{display: grid;}
     .login_frm_sect{width: 100%;margin: 0;padding: 15px;}
 
    .form_crd h3{font-size: 26px;}
    .frm_frog .otp_grp .form-control{width: 80px;height: 30px;}
    .frm_frog{margin-top: 20px;}
 
}
