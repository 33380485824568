.navbar {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) rgb(255, 255, 255) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.navbar-logo {
  color: #ffffff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.4rem;
}

.navbar-logo:hover {
  color: #ffffff;
  font-size: 1.6rem;
  margin-left: 0px;
  margin-right: 0px;
  transition: all 0.2s ease-out;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: rgba(255, 255, 255, 0.663);
  text-decoration: none;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0.5rem 8px;
}

.nav-links:hover {
  color: #ffffff;
  font-size: 1.4rem;
  margin-left: 0px;
  margin-right: 0px;
  padding: 0.5rem 12.5px;
  transition: all 0.3s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.4s ease;
  }

  .nav-menu.active {
    background: #24222288;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    backdrop-filter: blur(5px);
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    color: #fff;
    display: table;
  }

  .nav-links:hover {
    background-color: #994B00;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #994B00;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #994B00;
    transition: 250ms;
  }

}
